<template>
  <v-footer
    class="ctk-footer"
  >
    <template>
      <v-container grid-list-md text-center pt-5>
        <v-layout wrap>
          <!--<v-flex xs12 sm12 md2 lg2 pl-2 class="hidden-sm-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">关于我们</h5>
              <ul class="ctk-footer-link text-left">
                <li><a href="/about">关于柯尼</a></li>
                <li><a href="/process">发展历程</a></li>
                <li><a href="/culture">企业文化</a></li>
              </ul>
            </section>
          </v-flex>-->
          <v-flex xs12 sm12 md2 lg2 pl-2 class="hidden-sm-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">产品中心</h5>
              <ul class="ctk-footer-link text-left" v-if="info.product">
                <li v-for="cat in info.product.category.cn" :key="cat.id"><a :href="'/products/' + cat.id">{{cat.name}}</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md2 lg2 pl-2 class="hidden-sm-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">应用案例</h5>
              <ul class="ctk-footer-link text-left" v-if="info.cases">
                <li v-for="cat in info.cases.category.cn" :key="cat.id"><a :href="'/cases/' + cat.id">{{cat.name}}</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md2 lg2 pl-2 class="hidden-sm-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">修复运用</h5>
              <ul class="ctk-footer-link text-left" v-if="info.field">
                <li v-for="cat in info.field.category" :key="cat.id"><a :href="'/fields/' + cat.id">{{cat.name}}</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md2 lg2 pl-2 class="hidden-sm-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">技术培训</h5>
              <ul class="ctk-footer-link text-left" v-if="info.training">
                <li v-for="cat in info.training.category" :key="cat.id"><a :href="'/trainings/' + cat.id">{{cat.name}}</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md4 lg4 pl-2 class="hidden-sm-and-down">
            <div style="text-align:left;"><img :src="info.logo" /></div>
            <ul class="ctk-footer-link text-left">
              <li>
                <span>{{this.$t("footer.telephone")}}：</span><span>{{info.tel}}</span>
              </li>
              <li>
                <span>{{this.$t("footer.email")}}：</span><span>{{info.email}}</span>
              </li>
              <li>
                <span>{{this.$t("footer.address")}}：</span><span>{{info.addr}}</span>
              </li>
            </ul>
          </v-flex>
          <v-flex xs12 class="ctk-footer-copyright">
            &copy;2019 鹿尼德(上海)表面材料技术有限公司 | <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">备案号: {{info.icp}}</a>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  }
}
</script>
